import { deleteDataByUrl, getDataByUrl, postDataByUrl } from './index';

export const postRooms = async (candidate: any) =>
  postDataByUrl(`/api/messenger/rooms/`, candidate);
export const getAllRooms = async () => getDataByUrl(`/api/messenger/rooms/`);
export const getPrivateRooms = async (candidate: any) =>
  getDataByUrl(`/api/messenger/rooms/?candidate=${candidate}`);
export const getMessageRoom = async (room: any) =>
  getDataByUrl(`/api/messenger/messages/?room=${room}`);
export const getAllNotifications = async () =>
  getDataByUrl(`/api/notifications/all/`);
export const postMessage = async (data: any) =>
  postDataByUrl(`/api/messenger/messages/`, data);
export const deleteRoom = async (room: number) =>
  deleteDataByUrl(`/api/messenger/rooms/${room}/`);
export const getNotifications = async () =>
  getDataByUrl(`/api/notifications/unread/`);
export const getUnreadCount = async () =>
  getDataByUrl(`/api/notifications/api/unread_count/`);
export const getAllReadNotifications = async () =>
  getDataByUrl(`/api/notifications/mark-all-as-read/`);
export const getAsReadNotifications = async (id: any) =>
  getDataByUrl(`/api/notifications/mark-as-read/${id}/`);
export const getTemplates = async (name: string) =>
  getDataByUrl(`/api/templates/?limit=10&q=${name ?? ''}`);
export const postTemplates = async (data: any) =>
  postDataByUrl(`/api/templates/`, data);
export const postTemplateCount = async (id: number) =>
  postDataByUrl(`/api/templates/${id}/add_count/`);
export const getColleagues = async (name: string) =>
  getDataByUrl(`/api/account/?role=recruiters&q=${name ?? ''}`);
export const getPrivateMessages = async (userId: number, colleagueId: number) =>
  getDataByUrl(
    `/api/messenger/private_messages/?user1=${userId}&user2=${colleagueId}`
  );
export const postPrivateMessage = async (data: any) =>
  postDataByUrl(`/api/messenger/private_messages/`, data);

export const deletePrivate = async (userId: number, colleagueId: number) =>
  deleteDataByUrl(
    `/api/messenger/private_messages/delete_conversation/?user1=${userId}&user2=${colleagueId}`
  );
export const getCandidates = (name: string) =>
  getDataByUrl(`/api/candidate/?limit=7&q=${name ?? ''}`);